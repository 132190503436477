import { Component, createRef, h } from "preact";

import {
  IWidgetConfigurationProps,
  withWidgetConfiguration,
} from "@sgwt-widget/core";

const guestBaseUrl = "https://org.sgmarkets.world.socgen";
// const guestBaseUrl = "http://localhost:3000";

export interface IOrgChartEvent extends Record<string, unknown> {
  type: string;
}

export interface IOrgChartProps {
  data: object;
  orgId?: string;
  grid?: boolean;
  contactInfo?: boolean;
  disableLinks?: boolean;
  panZoom?: boolean;
  emitEvent(event: IOrgChartEvent): void;
}

type OrgChartProps = IOrgChartProps & IWidgetConfigurationProps;

interface IOrgChartState {
  guestReady: boolean;
}

export const OrgChart = withWidgetConfiguration(
  class extends Component<OrgChartProps, IOrgChartState> {
    public static displayName = "OrgChart";
    public state: IOrgChartState = { guestReady: false };

    private iframeRef = createRef<HTMLIFrameElement>();

    public componentDidMount() {
      window.addEventListener("message", this.handleMessage);
    }

    public componentWillUnmount() {
      window.removeEventListener("message", this.handleMessage);
    }

    public componentDidUpdate(prevProps: IOrgChartProps) {
      const { data, orgId, grid, contactInfo, disableLinks } = this.props;
      if (
        data !== prevProps.data ||
        orgId !== prevProps.orgId ||
        grid !== prevProps.grid ||
        contactInfo !== prevProps.contactInfo ||
        disableLinks !== prevProps.disableLinks
      ) {
        this.updateGuest();
      }
    }

    public render(): JSX.Element {
      return (
        <iframe
          ref={this.iframeRef}
          src={`${guestBaseUrl}/embed`}
          style={{ width: "100%", height: "100%" }}
          frameBorder="0"
        />
      );
    }

    private handleMessage = (e: MessageEvent) => {
      if (
        e.origin.indexOf(guestBaseUrl) === 0 &&
        e.source === this.guestWindow
      ) {
        if (e.data && e.data.type === "ready") {
          this.setState({ guestReady: true }, this.updateGuest);
        }
        this.props.emitEvent(e.data);
      }
    };

    private updateGuest = () => {
      if (!this.state.guestReady) {
        // tslint:disable-next-line
        console.error(
          "[sgm-org-chart] cannot update org chart because it is not ready. props:",
          this.props
        );
        return;
      }

      const {
        data,
        orgId,
        grid,
        contactInfo,
        disableLinks,
        panZoom,
      } = this.props;

      if (this.guestWindow) {
        const message = {
          source: "sgm-org-chart-widget",
          simulation: data,
          orgId,
          grid,
          contactInfo,
          disableLinks,
          panZoom,
        };
        this.guestWindow.postMessage(message, guestBaseUrl);
      }
    };

    private get guestWindow() {
      const iframe = this.iframeRef.current;
      return (iframe && iframe.contentWindow) || null;
    }
  }
);
