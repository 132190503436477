import {
  h,
  initializeWidgetConfiguration,
  SgWidget,
  WidgetConfigurationProvider,
  WithGlobalCss,
} from "@sgwt-widget/core";
import { ComponentProps, props, emit } from "skatejs";

import { OrgChart, IOrgChartEvent } from "./components/OrgChart";

export interface ISgmOrgChartProps {
  data: object;
  orgId?: string;
  grid?: boolean;
  contactInfo?: boolean;
  disableLinks?: boolean;
  panZoom?: boolean;
}

export class SgmOrgChart extends SgWidget<ISgmOrgChartProps> {
  public static is = "sgm-org-chart";

  public static props: ComponentProps<ISgmOrgChartProps> = {
    data: props.object,
    orgId: props.string,
    grid: props.boolean,
    contactInfo: props.boolean,
    disableLinks: props.boolean,
    panZoom: props.boolean,
  };

  public render() {
    return (
      <WidgetConfigurationProvider
        widgetConfiguration={this.widgetConfiguration}
      >
        <WithGlobalCss>
          <OrgChart {...this.props} emitEvent={this.emitEvent} />
        </WithGlobalCss>
      </WidgetConfigurationProvider>
    );
  }

  private emitEvent = (event: IOrgChartEvent) => {
    emit(this, `${SgmOrgChart.is}--${event.type}`, {
      detail: event,
    });
  };
}

initializeWidgetConfiguration(SgmOrgChart);
customElements.define(SgmOrgChart.is, SgmOrgChart);
